import React, { useEffect, useRef } from 'react';
import { atom } from 'jotai';
import { RoccoLink } from '../../../components/link/RoccoLink';
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from '../../../components/accordion';
import { Grid } from '../../layout/components/Grid';
import { NavigationMenu } from './NavigationMenu';
import { DisplayMode } from '../../../types/enum/display-mode';
import { useInViewport } from 'ahooks';
import { useSetAtom } from 'jotai';
import { uiStateAtomReachedFooter } from '../states/ui-states-reached-footer';
import { LogoAnimated } from '../../../components/logo-animated/LogoAnimated';

interface FooterTrackingConfig {
    onNavClick: (link: string) => void;
    onEmailSubscribe: (email: string) => void;
    onBottomLinkSectionCardClick: (index: number) => void;
}

export const uiFooterTrackingConfig = atom(get =>
    get(localFooterTrackingConfigAtom),
);
const localFooterTrackingConfigAtom = atom<FooterTrackingConfig | undefined>(
    undefined,
);

export interface FooterProps {
    subscriptionForm?: React.ReactNode;
    linksColumn1: FooterLinks[];
    linksColumn2: FooterLinks[];
    websiteTermLink: string;
    privacyPolicyLink: string;
    displayMode: DisplayMode;
    trackingConfig?: FooterTrackingConfig;
    isLegacyBrokenRemMode?: boolean;
    hideContactArchiPro?: boolean;
}

interface FooterLinks {
    title: string;
    links: Array<{
        title: string;
        to: string;
        isNonRemixRoute?: boolean;
    }>;
}

export const Footer = (props: FooterProps) => {
    const { displayMode, trackingConfig } = props;
    const setTrackingConfig = useSetAtom(localFooterTrackingConfigAtom);

    useEffect(() => {
        setTrackingConfig(trackingConfig);
    }, [trackingConfig, setTrackingConfig]);

    if (displayMode === DisplayMode.Desktop) {
        return <DesktopFooter {...props} />;
    }

    return <MobileFooter {...props} />;
};

const MobileFooter = (props: FooterProps) => {
    const {
        subscriptionForm,
        linksColumn1,
        linksColumn2,
        websiteTermLink,
        privacyPolicyLink,
        isLegacyBrokenRemMode,
        hideContactArchiPro,
    } = props;

    const containerRef = useRef<HTMLDivElement>(null);
    const [isVisible] = useInViewport(containerRef, {
        rootMargin: '-120px',
    });

    const setReachedFooter = useSetAtom(uiStateAtomReachedFooter);
    const newLinksColumn2 = hideContactArchiPro
        ? removeContactUsLink(linksColumn2)
        : linksColumn2;

    useEffect(() => {
        setReachedFooter(!!isVisible);
    }, [isVisible, setReachedFooter]);

    const privacyLinks = {
        title: 'Privacy',
        links: [
            {
                title: 'Terms',
                to: websiteTermLink,
            },
            {
                title: 'Privacy Policy',
                to: privacyPolicyLink,
            },
        ],
    };

    const links = linksColumn1.concat(newLinksColumn2);

    return (
        <footer ref={containerRef} className="bg-gray-100 pt-20">
            <section className="px-6">
                <div className="border-y border-gray-200 py-4 pb-12">
                    {subscriptionForm}
                </div>

                <Accordion type="single" collapsible>
                    {links.map(link => (
                        <AccordionItem key={link.title} value={link.title}>
                            <AccordionTrigger>{link.title}</AccordionTrigger>
                            <AccordionContent>
                                <NavigationMenu {...link} />
                            </AccordionContent>
                        </AccordionItem>
                    ))}

                    <AccordionItem
                        key={privacyLinks.title}
                        value={privacyLinks.title}
                    >
                        <AccordionTrigger>
                            {privacyLinks.title}
                        </AccordionTrigger>
                        <AccordionContent>
                            <NavigationMenu {...privacyLinks} />
                        </AccordionContent>
                    </AccordionItem>
                </Accordion>

                <div className="pb-17.5 pt-37">
                    <RoccoLink to="/" aria-label="Home">
                        <LogoAnimated
                            size={4}
                            mode="disabled"
                            isLegacyBrokenRemMode={isLegacyBrokenRemMode}
                        />
                    </RoccoLink>
                </div>
            </section>
        </footer>
    );
};

const DesktopFooter = (props: FooterProps) => {
    const {
        subscriptionForm,
        linksColumn1,
        linksColumn2,
        websiteTermLink,
        privacyPolicyLink,
        isLegacyBrokenRemMode,
        hideContactArchiPro,
    } = props;

    const containerRef = useRef<HTMLDivElement>(null);
    const [isVisible] = useInViewport(containerRef, {
        rootMargin: '-120px',
    });

    const setReachedFooter = useSetAtom(uiStateAtomReachedFooter);

    useEffect(() => {
        setReachedFooter(!!isVisible);
    }, [isVisible, setReachedFooter]);

    const newLinksColumn2 = hideContactArchiPro
        ? removeContactUsLink(linksColumn2)
        : linksColumn2;

    return (
        <footer ref={containerRef} className="bg-gray-100 px-12">
            <div className="qhd:mx-auto qhd:max-w-[1920px] qhd:px-12 h-268 pt-45 pb-12">
                <Grid colClasses="h-full grid-cols-1 md:grid-cols-4">
                    <div className="flex flex-col justify-between">
                        {subscriptionForm ?? <div />}

                        <RoccoLink to="/" prefetch="intent" aria-label="Home">
                            <LogoAnimated
                                size={4.5}
                                mode="loop"
                                isLegacyBrokenRemMode={isLegacyBrokenRemMode}
                            />
                        </RoccoLink>
                    </div>

                    <div className="border-r border-gray-900/10"></div>

                    <div className="flex flex-col justify-between border-r border-gray-900/10">
                        <div className="flex flex-col gap-y-14">
                            {linksColumn1.map((linksSection, index) => (
                                <NavigationMenu key={index} {...linksSection} />
                            ))}
                        </div>

                        <span className="text-base text-gray-900/50">
                            © ArchiPro {new Date().getFullYear()}
                        </span>
                    </div>

                    <div className="flex flex-col justify-between">
                        <div className="flex flex-col gap-y-20">
                            {newLinksColumn2.map((linksSection, index) => (
                                <NavigationMenu key={index} {...linksSection} />
                            ))}
                        </div>

                        <div className="flex flex-col gap-y-1">
                            <RoccoLink
                                to={websiteTermLink}
                                className="text-base text-gray-900/50 hover:text-gray-900/50"
                            >
                                Terms
                            </RoccoLink>

                            <RoccoLink
                                to={privacyPolicyLink}
                                className="text-base text-gray-900/50 hover:text-gray-900/50"
                            >
                                Privacy Policy
                            </RoccoLink>
                        </div>
                    </div>
                </Grid>
            </div>
        </footer>
    );
};

/**
 * Removes any links to the contact-us page from a column of footer links.
 * Used to conditionally hide the Contact ArchiPro link in the footer.
 *
 * @param linksColumn - Array of footer link groups to filter
 * @returns A new array with contact-us links removed from each group
 */
const removeContactUsLink = (linksColumn: FooterLinks[]) => {
    return linksColumn.map(group => ({
        ...group,
        links: group.links.filter(link => link.to !== '/contact-us'),
    }));
};
